import React from 'react'

const NoHiddenFees = ({ illustration, features }) => (
  <div className="container-md padding-xxl relative">

    <div className="grid yg between">
      <div className="col-7">
        <h6 className="eyebrow">Geen verrassingen achteraf</h6>
        <h2>Transparante kosten salarisadministratie</h2>
        <p>Employes houdt van duidelijkheid en dat is ook precies wat we bieden. Je betaalt een helder tarief en ons abonnement is maandelijks opzegbaar. Alle benodigdheden en advies voor je salarisverwerking zijn inbegrepen.</p>
      </div>

      <div className="hero-illustration">
        <img src={ illustration }/>
      </div>
    </div>

    <div className="grid padding-m-top">
      <div className="col-10">
        <div className="features">
          <ul>
            {
              features.map((feature, idx) => (<li key={`${feature}-${idx}`}>
                <span className="checkmark-container blue">
                  <svg width="13" height="10" viewBox="0 0 13 10">
                    <polyline fill="none" className="checkmark" strokeWidth="2" points="17.532 8 10.02 15.512 7 12.492" transform="translate(-6 -7)"/>
                  </svg>
                </span>
                {feature}
              </li>))
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default NoHiddenFees
