import React from 'react'
import { Link } from 'gatsby'
import {pricingCheck, personeelTransparancyIllustration, salarisHrIllustraion, hrIllustration} from 'images'
import Layout from '../../../layouts'
import { window } from 'browser-monads'

import PricingColumn from 'components/pricing-column'
import CtaRow from 'components/cta-row'
import FaqDropdown from 'components/faq-dropdown'
import NoHiddenFees from 'components/no-hidden-fees'
import Helmet from 'react-helmet'

const features = [
  'Gratis overstapservice',
  'Digitale loonstrookjes voor je werknemers',
  'Jaaropgaven voor alle werknemers',
  'Automatische loonaangifte bij de Belastingsdienst',
  'Automatische aangifte bij het pensioenfonds',
  'Onbeperkt wijzigingen doorvoeren',
  'Gratis toegang voor je boekhouder'
]

const dummyTable = {
  packageName : 'Salaris & HR',
  packageDescription : 'De complete oplossing',
  title: 'Inclusief:',
  price: '39,-',
  button: 'primary',
  prijsWerknemer: '8,-',
  features: [
    {description: '100% online salarisadministratie', active: true},
    {description: 'Alle salarisdocumenten (loonstroken, jaaropgave etc.)', active: true},
    {description: 'Koppelingen', active: true},
    {description: 'Verlof & verzuim', active: true, type: 'HR'},
    {description: 'Declaraties', active: true, type: 'HR'},
    {description: 'Contract templates', active: true, type: 'HR'},
    {description: 'Digitaal ondertekenen', active: false, type: 'ADD-ON'},
    {description: 'Urenregistratie', active: false, type: 'ADD-ON'}
  ]
}

const dummyTable2 = {
  packageName : 'Salaris',
  packageDescription : 'De basis benodigdheden',
  title: 'Inclusief:',
  price: '29,-',
  button: 'primary',
  prijsWerknemer: '6,50',
  features: [
    {description: '100% online salarisadministratie', active: true},
    {description: 'Alle salarisdocumenten (loonstroken, jaaropgave etc.)', active: true},
    {description: 'Koppelingen', active: true},
    {description: 'Verlof & verzuim', active: false},
    {description: 'Declaraties', active: false},
    {description: 'Contract templates', active: false},
    {description: 'Digitaal ondertekenen', active: false, type: 'ADD-ON'},
    {description: 'Urenregistratie', active: false, type: 'ADD-ON'}
  ]
}

const questions = [
  {
    title: 'Hoe lang is de proefperiode precies?',
    id: 'hoe-lang-proefperiode',
    content: 'Na het aanmaken van een account, kun je Employes zo lang als je wilt gratis en vrijblijvend uitproberen in de vorm van een demo-versie. Pas wanneer je aangeeft dat je daadwerkelijk met Employes aan de slag wil, zetten we je account op actief.',
    link: ''
  }, {
    title: 'Hoe ga ik van start met Employes?',
    id: 'hoe-start-ik',
    content: 'Nadat je een account hebt aangemaakt, begeleiden we je stap voor stap door de applicatie. Tijdens deze stappen, kun je alle relevante gegevens voor je salarisadministratie invullen. Daarna kun je binnen een paar minuten al de eerste loonstrook bekijken. Wil je daarna overstappen? Dan kun je aangeven dat je je account wilt activeren.',
    link: ''
  }, {
    title: 'Hoe werkt het overstappen?',
    id: 'hoe-werkt-overstappen',
    content: 'Om ervoor te zorgen dat je met Employes aan de slag kunt, hebben wij de volgende informatie nodig: de meest recente loonstroken van je werknemer(s), de laatste loonaangifte en de loonjournaalpost. Ons team regelt dat die informatie in de applicatie wordt ingeladen zodat je de salaris van de volgende maand automatisch in Employes kunt verwerken.',
    link: ''
  }, {
    title: 'Betaal ik ook voor oproepkrachten die niet werken?',
    id: 'oproepkrachten',
    content: 'Je hoeft alleen te betalen voor werknemers die in de betreffende maand daadwerkelijk gewerkt hebben. Voor oproepkrachten die niet werken, hoef je ook niet te betalen.',
    link: ''
  }, {
    title: 'Voldoet Employes aan de AVG?',
    id: 'employes-avg',
    content: 'Ja, met Employes voldoe je aan de regels die gelden voor de verwerking van persoonsgegevens op basis van de Algemene verordening gegevensbescherming.',
    link: ''
  }
]

class PersoneelPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="tarieven animated fadeInPage">

          <Helmet>
            <title>Tarieven salarisadministratie | Overzicht kosten | Employes</title>
            <meta name="description" content="Bekijk nu de tarieven om je salarisadministratie snel te regelen. Voor een vaste prijs, alles inbegrepen. Zonder verborgen kosten. Probeer nu gratis." />
            <meta itemprop="name" content="Tarieven salarisadministratie | Overzicht kosten | Employes" />
            <meta itemprop="description" content="Bekijk nu de tarieven om je salarisadministratie snel te regelen. Voor een vaste prijs, alles inbegrepen. Zonder verborgen kosten. Probeer nu 1 gratis."/>
            <meta itemprop="image" content="/static/meta-img.png" alt="Employes tarieven"/>
          </Helmet>

          <div className="container-md padding-xxl">
            <div className="grid center text-center">
              <div className="col-7">
                <h1>Tarieven Salarisadministratie</h1>
                <p className="streamer large center">Met Employes kies je voor moderne salarisadministratie die meegroeit met jouw bedrijf. Alles voor een helder tarief. Zonder verborgen kosten.</p>
              </div>
            </div>
          </div>

          <div className="pricing-columns">
            <div className="backdrop"></div>

            <div className="container-sm ">
              <div className="grid yg padding-m-bottom">
                <div className="col-6">
                  <PricingColumn data={dummyTable2} className="blue" headerIllustration={hrIllustration}/>
                </div>
                <div className="col-6">
                  <PricingColumn data={dummyTable} className="blue" headerIllustration={salarisHrIllustraion}/>
                </div>
              </div>

              <div className="grid yg center text-center streamer">
                <div className="col-10">
                  <p>Ben je DGA en heb je een holding? Ontdek dan het <Link to="/tarieven/dga-holding/" className="link inline">DGA-pakket</Link> van € 14,95 (excl. btw).</p>
                </div>
              </div>
            </div>
          </div>

          <NoHiddenFees illustration={personeelTransparancyIllustration} features={features}/>

          <CtaRow light={true} internallink={true} linkTo="/overstapservice/" text="Gratis overstapservice" subtext="Het overstappen met je salarisadministratie kan een flinke klus lijken. Maar met Employes valt dat mee. Wij bieden namelijk een gratis overstapservice aan waarmee we al het werk uit handen nemen voor je." btnText="Hoe werkt overstappen?" eyebrow="Overstappen"/>

          <div className="container-md padding-xxl">
            <div className="grid">
              <div className="col-6">
                <h6 className="eyebrow">Overstappen</h6>
                <h2>Veelgestelde vragen</h2>
                <p className="margin-m-bottom">Overstappen van de ene naar de andere oplossing voor je salarisadministratie kan nogal wat vragen oproepen. Wij zetten de veelgestelde vragen en antwoorden voor je op een rij zodat je doordacht je keuze kunt maken.</p>
                <Link to="/faq/" className="btn secondary no-max lg">Bekijk veelgestelde vragen</Link>
              </div>

              <div className="col-6 faq-block">
                {questions.map((question, idx) => (
                  <FaqDropdown question={question} idx={idx} setActive={this.setActive} activeID={this.state.activeID} key={idx}/>
                ))}
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default PersoneelPage
