import React from 'react'
import config from '../config'
const { appUrl, signupRoute } = config
import NumberFormat from 'react-number-format'

const PricingColumn = props => (
  <div
    className={`pricing-column ${
      props.className !== undefined ? props.className : ''
    }`}
  >
    <div>
      <div className="column-heading">
        <div className="grid yg center">
          <div className="col-8">
            <h3 className="no-margin">{props.data.packageName}</h3>
            <h5>{props.data.packageDescription}</h5>
          </div>

          <div className="col-4 illu">
            <img src={props.headerIllustration} />
          </div>
        </div>
      </div>

      <div className="column-content">
        <h6>{props.data.title}</h6>

        <div className="rows">
          {props.data.features.map((feature, idx) => (
            <div key={`${feature}-${idx}`} className="row">
              {feature.active === true ? (
                <span className="row-checkmark checkmark-container">
                  <svg width="13" height="10" viewBox="0 0 13 10">
                    <polyline
                      fill="none"
                      className="checkmark"
                      strokeWidth="2"
                      points="17.532 8 10.02 15.512 7 12.492"
                      transform="translate(-6 -7)"
                    />
                  </svg>
                </span>
              ) : (
                <span className="row-checkmark checkmark-container inactive"></span>
              )}

              {feature.active === true ? (
                <span className="feature">{feature.description}</span>
              ) : (
                <span className="feature inactve">{feature.description}</span>
              )}

              {feature.type ? (
                <div className="popover-label">
                  <span>{feature.type}</span>
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="column-footer">
      <div className="grid center text-center">
        <div className="col-12">
          <div className="price-container">
            <span className="price">
              <h1 className="no-margin padding-xxs-bottom">
                {' '}
                {props.data.prijsWerknemer}
                <small>per loonstrook</small>
              </h1>
            </span>
          </div>
          <p className="padding-m-bottom">
          + € {props.data.price} per maand (excl. btw)*
          </p>

          {/*<a className="btn primary lg center no-max">Probeer 30 dagen gratis</a>*/}

          {props.data.button === 'secondary' ? (
            <a className="btn secondary lg center no-max">
              Binnenkort beschikbaar
            </a>
          ) : (
            <a
              className="btn primary lg center no-max"
              href={appUrl + signupRoute}
            >
              Probeer nu gratis
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default PricingColumn
