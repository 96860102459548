import React from 'react'
import {chevron} from 'images'
import { Link } from 'gatsby'

class FaqDropdown extends React.Component {
  render() {
    const {data, question, idx, setActive, activeID} = this.props

    return (<div className={`questions-block ${activeID === question.id
        ? 'open'
        : ''}`} id={question.id} key={idx}>
      <div className="question">
        <div className="question-top" onClick={setActive} id={question.id}>
          <h6>{question.title}</h6>

          <svg width="14" height="8" viewBox="0 0 14 8">
            <polygon fill="#B0B2CB" points="632.525 33 634 34.394 626.988 41 620 34.393 621.477 33.001 626.991 38.214" transform="translate(-620 -33)"/>
          </svg>
        </div>

        <p>{question.content}</p>

        { question.link ?
          <Link href={question.link} className="link blue margin-s-top">
          Lees meer
          <span className="arrow right"></span>
        </Link>
        : ""
        }

      </div>
    </div>)
  }
}

export default FaqDropdown
